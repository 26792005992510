<template>
    <div class="">
        <div class="bgbgbg"></div>
        <div class="bg_model"></div>
        <div class="login">
            <div class="logo">
                <img src="../../../public/word.png" alt=""/>
            </div>
            <div class="tab_title">
                <div :class="type == 0 ? 'item active' : 'item'" @click="changeType">
                    账号登录
                </div>
                <div :class="type == 1 ? 'item active' : 'item'" @click="changeType">
                    手机号登录
                </div>
            </div>

            <!-- 账号登录 -->
            <div class="tab_con" v-if="type == 0">
                <div class="tab_con_word">
                    手机号<span>*</span>
                </div>
                <div class="tab_con_input">
                    <!--                    <img src="../../../public/icon-account.png" alt=""/>-->
                    <img src="../../../public/icon-iphone@2x.png" alt=""/>
                    <input type="text" name="phone" maxlength="11" v-model="phone" placeholder="请输入手机号"/>
                </div>
                <div class="tab_con_word">
                    密码<span>*</span>
                </div>
                <div class="tab_con_input">
                    <img src="../../../public/icon-password.png" alt=""/>
                    <input type="password" name="password" v-model="password" placeholder="请输入密码"/>
                </div>
                <div class="contr">
                    <div class="item" @click="forget">
                        <p>忘记密码</p>
                        <img src="../../../public/icon-forget@2x.png" alt=""/>
                    </div>
                    <div class="item" @click="register">
                        <p>去注册</p>
                        <img src="../../../public/icon-right.png" alt=""/>
                    </div>
                </div>
            </div>
            <div class="button" v-if="type == 0">
                <p @click="loginByPassword">登录</p>
            </div>

            <!--手机号登录 -->
            <div class="tab_con" v-if="type == 1">
                <div class="tab_con_word">
                    手机号<span>*</span>
                </div>
                <div class="tab_con_input">
                    <img src="../../../public/icon-iphone@2x.png" alt=""/>
                    <input type="number" name="phone" maxlength="11" v-model="phone" placeholder="请输入手机号"/>
                </div>
                <div class="tab_con_word">
                    验证码<span>*</span>
                </div>
                <div class="tab_con_input">
                    <img src="../../../public/icon-code@2x.png" alt=""/>
                    <input type="number" name="verify_code" maxlength="6" v-model="verify_code" placeholder="请输入验证码"/>
                    <span style="color: #EB9114;" v-if="isSend" @click="sendCode">{{ sendCodeStr }}</span>
                    <span v-else>{{ sendCodeStr }}</span>
                </div>
                <div class="contr">
                    <div class="item" @click="forget">
                        <p>忘记密码</p>
                        <img src="../../../public/icon-forget@2x.png" alt=""/>
                    </div>
                    <div class="item" @click="register">
                        <p>去注册</p>
                        <img src="../../../public/icon-right.png" alt=""/>
                    </div>
                </div>
            </div>
            <div class="button" v-if="type == 1">
                <p @click="loginByCode">登录</p>
            </div>


        </div>
    </div>
</template>
<script>
import {hexMD5} from "@/utils/md5";
let timeStop;
export default {
    name: "login",
    data() {
        return {
            type: 0,
            phone: '',
            password: '',
            verify_code: '',
            sendCodeStr: '获取验证码',
            isSend: true,
        };
    },
    created() {
    },
    mounted() {
    },

    methods: {
        changeType() {
            this.type = this.type == 0 ? 1 : 0;
        },

        sendCode() {
            if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))) {
                this.$message({
                    message: "请输入正确手机号",
                    type: "warning",
                });
                return false;
            }
            if (this.isSend) {
                const loading = this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.isSend = false;
                this.$http.post('/common/sendSms', {phone: this.phone, sign: hexMD5(this.phone)}).then(res => {
                    loading.close();
                    if (res.data.code == 0) {
                        this.$message({
                            message: "发送成功",
                            type: "success",
                        });
                        this.countDown();
                    }else{
                        this.isSend = true;
                        this.$message({type: 'error', message: res.data.msg})
                    }
                });
            } else {
                return false;
            }
        },
        countDown() {
            var timeo = 60;
            var that = this;
            timeStop = setInterval(function () {
                timeo--;
                if (timeo > 0) {
                    that.isSend = false;
                    that.sendCodeStr = '重新发送' + timeo + 's';
                } else {
                    timeo = 60;
                    that.isSend = true;
                    that.sendCodeStr = '获取验证码';
                    clearInterval(timeStop);
                }
            }, 1000)
        },

        forget() {
            this.$router.push('/forget')
        },
        register() {
            this.$router.push('/register')
        },
        loginByPassword() {
            if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))) {
                this.$message({
                    message: "请输入正确手机号",
                    type: "warning",
                });
                return false;
            }
            if(this.password.length == 0){
                this.$message({
                    message: "请输入密码",
                    type: "warning",
                });
                return false;
            }
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.post('/doLoginByPassword', {phone: this.phone, password: this.password}).then(res => {
                loading.close();
                if (res.data.code == 0) {
                    localStorage.setItem('access_token', res.data.data.access_token)
                    this.$router.push('/')
                }else{
                    this.$message({type: 'error', message: res.data.msg})
                }
            })
        },

        loginByCode(){
            if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))) {
                this.$message({
                    message: "请输入正确手机号",
                    type: "warning",
                });
                return false;
            }
            if(this.verify_code.length == 0){
                this.$message({
                    message: "请输入验证码",
                    type: "warning",
                });
                return false;
            }
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.post('/doLoginByCode', {
                phone: this.phone,
                verify_code: this.verify_code,
            }).then(res => {
                loading.close();
                if (res.data.code == 0) {
                    localStorage.setItem('access_token', res.data.data.access_token)
                    this.$router.push('/')
                }else{
                    this.$message({type: 'error', message: res.data.msg})
                }
            });
        },
    },
};
</script>
<style lang='less' scoped>
.login {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    width: 560px;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 16px 16px 16px 16px;
    padding: 45px;
    box-sizing: border-box;

    .logo {
        img {
            display: block;
            width: 320px;
            margin: 0 auto;
        }
    }

    .tab_title {
        margin-top: 28px;
        display: flex;
        align-items: center;

        .item {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 18px;
            color: #8D8D8D;
            line-height: 28px;
            text-align: center;
            flex: 1;
        }

        .item.active {
            color: #231815;
            position: relative;
        }

        .item.active:after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            bottom: -10px;
            transform: translate(-50%, 0);
            width: 76px;
            height: 5px;
            background: #EB9114;
        }
    }

    .tab_con {
        margin-top: 20px;

        .tab_con_word {
            display: flex;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #303133;
            line-height: 24px;
            margin-bottom: 14px;

            span {
                color: #D91C17;
            }
        }

        .tab_con_input {
            display: flex;
            align-items: center;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #CDD0D6;
            padding: 12px 10px;
            margin-bottom: 32px;

            img {
                display: block;
                width: 24px;
                margin-right: 16px;
            }

            input {
                flex: 1;
                color: #333;
                font-size: 14px;
                background: none;
            }
        }

        .contr {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;

            .item {
                cursor: pointer;
                display: flex;
                align-items: center;

                p {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #847767;
                    line-height: 22px;
                    margin-right: 6px;
                }

                img {
                    display: block;
                    width: 16px;
                }
            }
        }
    }

    .button {
        margin-top: 50px;

        p {
            width: 100%;
            height: 66px;
            background: linear-gradient(16deg, #F08300 0%, #F08300 21%, #FFF100 100%);
            border-radius: 66px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 24px;
            color: #FFFFFF;
            line-height: 66px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>
